import React from "react";

import Layout from "../../../components/base/layout";
import SEO from "../../../components/base/seo";

const AdditionalEmailNewsletter = () => (
  <Layout>
    <SEO title="Additional Email Newsletter Addons" />
    <div className="container">
      <section className="section-checkout-form page-heading">
        <div className="checkout-product-heading">
          <h2>Additional Email Newsletter| $550 CAD/per email </h2>
        </div>
        <div className="checkout-form-details">
          <div className="checkout-desc">
            <h5 className="heading">INCLUDED:</h5>
            <ul>
              <li>1 x 15-minute planning call with our team </li>
              <li>
                1 x email newsletter including:
                <ul className="ul-circle">
                  <li>Email flow content outlines & copywriting</li>
                  <li>Creation of email graphics </li>
                  <li>Build of email</li>
                  <li>Pre-flight bug check & scheduling </li>
                </ul>
              </li>
            </ul>

            <p className="text-small">
              <i>
                Note: This package does not include the cost of automated
                marketing software or CRM or paid integration plugins or tools.
                We do not charge a markup on those, and they will need to be
                purchased directly by the client, using their preferred payment
                method.
              </i>
            </p>
            <p className="text-red">
              This can only be purchased as an add-on to one of our other
              monthly email packages. Please return to the email packages page
              and select this add-on during the checkout process.
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default AdditionalEmailNewsletter;
